import React from 'react';
import '../App.css'
const Custombtn = () => {
  return (
    
    <button className='btnofsection'>
      <span className='btn-text' style={{textAlign: 'center'}}>Major General Muhammad Imtiaz Khan, HI(M) (Retd)</span>
    </button>
  );
};

export default Custombtn