import React from 'react'
import Form from '../components/Form'
import Pharpage1 from '../components/Pharmaciespage'
import Phsection2 from '../components/Phsection2'
import Phsection3 from '../components/Phsection3'
import Phsection4 from '../components/Phsection4'
import Phsection5 from '../components/Phsection5'



function PharmaciesPage() {
  return (
<>
        <Pharpage1/>
        <Phsection2/>
        <Phsection3/>
        <Phsection4/>
        <Phsection5/>
        <Form/>
        </>
  )
}

export default PharmaciesPage