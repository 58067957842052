import '../App.css'
function DocHead(){

return (
    <div className='headofsection3'>
    <h1 className="h1ofhead1">MedAsk</h1>
    <h2 className='h2ofhead1'>Our Doctors</h2>
    </div>
);
}
export default DocHead