import React from 'react'
import '../App.css'
function Head3(){

return (
    <div>
    <h1 className="h1ofhead3 ibrtitle1">MedAsk</h1>
    <h2 className='h2ofhead3 subtitle'>Our Head Office</h2>
    </div>
);
}
export default Head3