import React from 'react'
import FormContact from '../components/FormContact'
import Con1 from '../components/Contact1'
import { Container } from '@mui/material'
import '../App.css'
function ContactUsPage() {
  return (

<>
      <Con1/>
      <FormContact/>

              
              </>
  )
}

export default ContactUsPage