
function About3icon1() {
    return (
        <svg className="about1icon1" height="40px" width="40px" version="1.1" id="Capa_1"
            viewBox="0 0 63.287 63.287">
            <path d="M60.993,20.124c0-4.404-3.583-7.987-7.987-7.987s-7.987,3.583-7.987,7.987
	c0,4.065,3.054,7.423,6.987,7.918v20.74c0,6.896-5.61,12.505-12.506,12.505h-8.43c-6.896,0-12.506-5.61-12.506-12.505V36.945h3.572
	c4.458,0,7.064-4.508,7.739-7.587c0.104-0.625,2.575-15.366,2.941-20.494c0.139-1.929-0.544-3.806-1.92-5.284
	c-1.503-1.614-3.628-2.54-5.83-2.54h-1.422C23.599,0.463,23.126,0,22.537,0H20.52c-0.619,0-1.125,0.506-1.125,1.125v1.829
	c0,0.619,0.506,1.125,1.125,1.125h2.016c0.589,0,1.062-0.462,1.108-1.04h1.422c1.648,0,3.24,0.694,4.366,1.903
	c0.994,1.067,1.487,2.409,1.39,3.778c-0.36,5.033-2.895,20.153-2.91,20.256c-0.507,2.307-2.522,5.967-5.776,5.967h-9.145
	c-3.254,0-5.27-3.66-5.767-5.918C7.2,28.874,4.666,13.755,4.305,8.721c-0.098-1.369,0.396-2.71,1.39-3.778
	C6.821,3.734,8.413,3.04,10.061,3.04h1.402c0.046,0.577,0.519,1.04,1.108,1.04h2.016c0.619,0,1.125-0.506,1.125-1.125V1.125
	C15.714,0.506,15.207,0,14.588,0h-2.016c-0.589,0-1.062,0.463-1.108,1.04h-1.402c-2.202,0-4.326,0.926-5.83,2.541
	c-1.376,1.478-2.058,3.354-1.92,5.283c0.366,5.127,2.837,19.869,2.951,20.542c0.665,3.03,3.271,7.539,7.729,7.539h3.572v11.836
	c0,7.999,6.507,14.505,14.506,14.505h8.43c7.999,0,14.506-6.507,14.506-14.505v-20.74C57.939,27.547,60.993,24.189,60.993,20.124z
	 M53.006,26.111c-3.302,0-5.987-2.686-5.987-5.987s2.686-5.987,5.987-5.987s5.987,2.686,5.987,5.987S56.307,26.111,53.006,26.111z
	 M53.006,16.732c-1.87,0-3.392,1.521-3.392,3.392s1.521,3.392,3.392,3.392s3.392-1.521,3.392-3.392S54.876,16.732,53.006,16.732z
	 M53.006,21.515c-0.768,0-1.392-0.625-1.392-1.392s0.624-1.392,1.392-1.392s1.392,0.625,1.392,1.392S53.773,21.515,53.006,21.515z"
            />
        </svg>);
}

export default About3icon1