import React from 'react'
import Form from '../components/Form'
import AboutUsSec1 from '../components/AboutUsSec1'
import About1 from '../components/About1'
import About2 from '../components/About2'
import About3 from '../components/About3'
import About4 from '../components/about4'
function AboutUsPage() {
  return (
    <div>

        <AboutUsSec1/>
        <About1/>
        <About2/>
        <About3/>
        <About4/>
        <Form/>
    </div>
  )
}

export default AboutUsPage