import '../App.css'
function Icon() {
    return (
        <div>
            <svg className='icon1' xmlns="http://www.w3.org/2000/svg" viewBox="4512 740 106.154 80">

                <g transform="translate(4512 740)">
                    <path d="M103.736,63.507H97.363V12.3a2.2,2.2,0,0,0-2.2-2.2H81.538a2.2,2.2,0,0,0-2.2,2.2V63.507H73.626V27.682a2.2,2.2,0,0,0-2.2-2.2H57.8a2.2,2.2,0,0,0-2.2,2.2V63.507H49.89v-44.4a2.2,2.2,0,0,0-2.2-2.2H34.066a2.2,2.2,0,0,0-2.2,2.2v44.4H26.374V35.375a2.2,2.2,0,0,0-2.2-2.2H10.769a2.2,2.2,0,0,0-2.2,2.2V63.507H2.2A2.2,2.2,0,0,0,0,65.7a2.076,2.076,0,0,0,2.2,2.2H103.956a2.2,2.2,0,0,0,2.2-2.2A2.259,2.259,0,0,0,103.736,63.507Zm-81.538,0H12.747V37.573H22.2Zm23.516,0H36.264v-42.2h9.451Zm23.736,0H60V29.88h9.451V63.507Zm23.736,0H83.736V14.5h9.451Z" transform="translate(0 12.098)"></path>
                    <path d="M12.432,40.44a8.113,8.113,0,0,0,8.132-8.132,6.084,6.084,0,0,0-.659-2.857L31.113,21.1a7.68,7.68,0,0,0,4.835,1.758,8.483,8.483,0,0,0,6.374-3.077l9.451,3.956v1.1a8.132,8.132,0,0,0,16.264,0A11.031,11.031,0,0,0,67.6,22.2l10.989-7.692a8,8,0,0,0,5.055,1.978,8.113,8.113,0,0,0,8.132-8.132A8.538,8.538,0,0,0,83.2,0a8.113,8.113,0,0,0-8.132,8.132,11.031,11.031,0,0,0,.44,2.637L64.52,18.462a8,8,0,0,0-5.055-1.978,8.483,8.483,0,0,0-6.374,3.077L43.641,15.6v-1.1a8.113,8.113,0,0,0-8.132-8.132c-4.615,0-7.692,3.956-7.692,8.352a6.084,6.084,0,0,0,.659,2.857L17.267,25.934a7.68,7.68,0,0,0-4.835-1.758A8.113,8.113,0,0,0,4.3,32.308,7.978,7.978,0,0,0,12.432,40.44ZM83.2,4.176a3.736,3.736,0,1,1,0,7.473,2.788,2.788,0,0,1-1.319-.22,3.572,3.572,0,0,1-2.418-3.3h0A3.9,3.9,0,0,1,83.2,4.176ZM57.487,21.538a3.545,3.545,0,0,1,1.978-.659,2.788,2.788,0,0,1,1.319.22A3.87,3.87,0,0,1,63.2,24.615h0a3.7,3.7,0,0,1-3.736,3.736,3.968,3.968,0,0,1-3.736-3.077v-.659A4.041,4.041,0,0,1,57.487,21.538ZM35.948,10.989a3.968,3.968,0,0,1,3.736,3.077v.659A3.508,3.508,0,0,1,37.926,17.8a3.545,3.545,0,0,1-1.978.659,2.788,2.788,0,0,1-1.319-.22,3.572,3.572,0,0,1-2.418-3.3v-.22A3.851,3.851,0,0,1,35.948,10.989ZM12.432,28.571a2.788,2.788,0,0,1,1.319.22,3.829,3.829,0,0,1,2.637,3.516v.22a3.736,3.736,0,1,1-7.473,0A3.365,3.365,0,0,1,12.432,28.571Z" transform="translate(5.151)"></path>
                </g>
            </svg>
        </div>
    )
}

export default Icon